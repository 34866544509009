
export default function Footer1 (){

    return (
     <footer className="footer">
         <p >© 2023 All Rights Reserved Created By <b>Rima Designer</b> </p>
     </footer>
     
     
   

   )


}